body {
background: linear-gradient(0deg, #85FFBD 0%, #FFFB7D 100%);

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  background-color: transparent;
  height: 50vh;
  width: 100vw;
  display: flex;
  }
.text{
    height: 100%;
    width: 100%;
    background:url(cono_dance.gif);
    background-size: 100% 100%;
    font-size: 180px;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
